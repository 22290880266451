<template>
  <div class="bg-yellow flex flex-col items-start mt-8 w-11/12 self-center p-8">
		<h2 class="mb-4 text-2xl font-bold uppercase">Compras</h2>
		<div class="flex flex-col bg-white w-full py-7 px-10 border-2 border-black rounded-xl">
			<div class="flex justify-end mb-4">
				<router-link
					class="p-2 rounded-lg bg-yellow hover:bg-yellow-500 font-bold uppercase"
					style="color: white;"
					to="/dashboard/cargar-compras"
				> 
						Cargar compras 
				</router-link>
			</div>
			<div class="w-full max-h-[47vh] overflow-y-auto">
				<table class="table-auto w-full">
					<thead>
						<tr>
							<th :class="[ !loadingData ? 'sticky z-10 top-0' : '' ]" class="bg-white p-2">#</th>
							<th :class="[ !loadingData ? 'sticky z-10 top-0' : '' ]" class="bg-white p-2">Fecha de carga</th>
							<th :class="[ !loadingData ? 'sticky z-10 top-0' : '' ]" class="bg-white p-2">Registros</th>
							<th :class="[ !loadingData ? 'sticky z-10 top-0' : '' ]" class="bg-white p-2">Creado por</th>
							<th :class="[ !loadingData ? 'sticky z-10 top-0' : '' ]" class="bg-white p-2">Revertido por</th>
							<th :class="[ !loadingData ? 'sticky z-10 top-0' : '' ]" class="bg-white p-2">Fecha de reversión</th>
							<th :class="[ !loadingData ? 'sticky z-10 top-0' : '' ]" class="bg-white p-2">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr class="mb-2" v-show="dataTransactions.length > 0" v-for="(transaction, index) in dataTransactions" :key="transaction.timeStamp">
							<td class="text-xs lg:text-base text-center pb-2">{{ index + 1 }}</td>
							<td class="text-xs lg:text-base text-center pb-2">{{ new Date(transaction.timeStamp).toLocaleDateString() + ' a las ' + new Date(transaction.timeStamp).toLocaleTimeString() }}</td>
							<td class="text-xs lg:text-base text-center pb-2">{{ transaction.length }}</td>
							<td class="text-xs lg:text-base text-center pb-2">{{ transaction.createdBy }}</td>
							<td class="text-xs lg:text-base text-center pb-2">{{ transaction.updatedBy ? transaction.updatedBy : 'N/A' }}</td>
							<td class="text-xs lg:text-base text-center pb-2">{{ transaction.timeStampUpdated ? new Date(transaction.timeStampUpdated).toLocaleDateString() + ' a las ' + new Date(transaction.timeStampUpdated).toLocaleTimeString() : 'N/A' }}</td>
							<td class="text-xs lg:text-base text-center pb-2">
								<button 
									class="p-2 rounded-lg bg-yellow hover:bg-yellow-500 disabled:bg-gray-500 disabled:cursor-not-allowed text-white text-xs font-bold uppercase"
									:disabled="!transaction.canBeReversed"
									@click="() => deleteTansactionWithId(transaction.transactionId)"
								> 
									REVERTIR 
							</button>
							</td>
						</tr>						
						<tr v-show="dataTransactions.length === 0">
							<td colspan="7" class="text-center pt-4"> No se han encontrado datos </td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<Loader :isLoading="loadingData" />
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import Loader from "@/components/Loader";

	export default {
		components: { Loader },
		data: () => ({ loadingData: false, dataTransactions: [] }),
		mounted() {
      this.loadTansactions()
    },
		methods: {
			...mapActions(["purchases/getPurchaseTransactions", "purchases/deleteTansactionWithId"]),

			async loadTansactions () {
				this.loadingData = true
				const res = await this["purchases/getPurchaseTransactions"]()
				this.loadingData = false
				this.dataTransactions = res
				
			},

			async deleteTansactionWithId (transactionId) {
				const confirmDeleteTransaction = confirm('¿Estás segur@ de realizar esta acción?')

				if ( !confirmDeleteTransaction ) return

				this.loadingData = true
				await this["purchases/deleteTansactionWithId"](transactionId)
				await this.loadTansactions()
				this.loadingData = false
				
			},
		}
	}
</script>